export default [
  {
    title: 'DASHBOARD',
    route: 'dashboard',
    icon: 'HomeIcon',
    resource: 'Dashboard',
    action: 'manage',
    variant: 'primary',
  },
  {
    title: 'B2BPRO',
    icon: 'ShoppingCartIcon',
    children: [
      {
        title: 'Order Tracker',
        route: 'order-tracker',
        icon: 'PlusCircleIcon',
        resource: 'OrderTracker',
        action: 'manage',
        variant: 'secondary',
      },
      {
        title: 'Users',
        route: 'users',
        icon: 'ArrowUpLeftIcon',
        resource: 'B2BProUsers',
        action: 'manage',
        variant: 'secondary',
      },
      {
        title: 'Markets',
        route: 'markets',
        icon: 'ClipboardIcon',
        resource: 'Markets',
        action: 'manage',
        variant: 'secondary',
      },
      {
        title: 'Vendors',
        route: 'vendors',
        icon: 'CastIcon',
        resource: 'Vendors',
        action: 'manage',
        variant: 'secondary',
      },
      {
        title: 'Inquiries',
        route: 'inquiries',
        icon: 'InboxIcon',
        resource: 'Inquiries',
        action: 'manage',
        variant: 'secondary',
      },
      {
        title: 'Payment Status',
        route: 'b2bpro-payment-status',
        icon: 'DollarSignIcon',
        resource: 'B2BProPaymentStatus',
        action: 'manage',
        variant: 'secondary',
      },
    ],
  },
  {
    title: 'FREIGHT FORWARDING',
    icon: 'SendIcon',
    children: [
      {
        title: 'Order Tracker',
        route: 'freight-forwarding-order-tracker',
        icon: 'PlusCircleIcon',
        resource: 'Auth',
        action: 'manage',
        variant: 'secondary',
      },
      {
        title: 'Port',
        route: 'freight-forwarding-port',
        icon: 'WindIcon',
        resource: 'Auth',
        action: 'manage',
        variant: 'secondary',
      },
      {
        title: 'Inquiries',
        route: 'freight-forwarding-inquiries',
        icon: 'HelpCircleIcon',
        resource: 'Auth',
        action: 'manage',
        variant: 'secondary',
      },
      {
        title: 'Payment Status',
        route: 'freight-forwarding-payment-status',
        icon: 'CreditCardIcon',
        resource: 'Auth',
        action: 'manage',
        variant: 'secondary',
      },
    ],
  },
  {
    title: 'CHARTERED TRUCK',
    icon: 'TruckIcon',
    children: [
      {
        title: 'Order Tracker',
        route: 'chartered-truck-order-tracker',
        icon: 'PlusCircleIcon',
        resource: 'Auth',
        action: 'manage',
        variant: 'secondary',
      },

      {
        title: 'Inquiries',
        route: 'chartered-truck-inquiries',
        icon: 'HelpCircleIcon',
        resource: 'Auth',
        action: 'manage',
        variant: 'secondary',
      },
      {
        title: 'Payment Status',
        route: 'chartered-truck-payment-status',
        icon: 'CreditCardIcon',
        resource: 'Auth',
        action: 'manage',
        variant: 'secondary',
      },
    ],
  },
  {
    title: 'SMARTTRUCK',
    icon: 'TruckIcon',
    children: [
      {
        title: 'Assigned Tracker',
        route: 'assigned-tracker',
        icon: 'ListIcon',
        resource: 'AssignedTracker',
        action: 'manage',
        variant: 'purple',
      },
      {
        title: 'Drivers',
        route: 'drivers',
        icon: 'UsersIcon',
        resource: 'Drivers',
        action: 'manage',
        variant: 'purple',
      },
      {
        title: 'Cancellations',
        route: 'cancellation-requests',
        icon: 'XSquareIcon',
        resource: 'CancellationRequests',
        action: 'manage',
        variant: 'purple',
      },
    ],
  },
  {
    title: 'TRANSACTIONS',
    route: 'transactions',
    icon: 'ClipboardIcon',
    resource: 'Transactions',
    action: 'manage',
    variant: 'yellow',
  },
  {
    title: 'COVERAGE',
    route: 'coverage',
    icon: 'MapPinIcon',
    resource: 'Coverage',
    action: 'manage',
    variant: 'orange',
  },
  {
    title: 'TRACKING',
    route: 'tracking',
    icon: 'MapIcon',
    resource: 'Tracking',
    action: 'manage',
    variant: 'hotpink',
  },
  {
    title: 'ACCESSIBILITY',
    icon: 'LockIcon',
    children: [
      {
        title: 'Admin Access',
        route: 'admin-access',
        resource: 'AdminAccess',
        action: 'manage',
        variant: 'green',
      },
      {
        title: 'Role Access',
        route: 'role-access',
        resource: 'RoleAccess',
        action: 'manage',
        variant: 'green',
      },
    ],
  },
  {
    title: 'PRICE CHECKER',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'Price Algorithm',
        route: 'price-algorithm',
        resource: 'PriceAlgorithm',
        action: 'manage',
        variant: 'maroon',
      },
      {
        title: 'Price Logs',
        route: 'price-algorithm-logs',
        resource: 'PriceAlgorithmLogs',
        action: 'manage',
        variant: 'maroon',
      },
    ],
  },
  {
    title: 'REPORTS',
    route: 'reports',
    icon: 'FileIcon',
    resource: 'Reports',
    action: 'manage',
    variant: 'warning',
  },
  {
    title: 'ANALYTICS',
    icon: 'BarChart2Icon',
    children: [
      {
        title: 'Orders',
        icon: 'BoxIcon',
        route: 'orders-analytics',
        resource: 'OrdersAnalytics',
        action: 'manage',
        variant: 'lightblue',
      },
      {
        title: 'Shipments',
        icon: 'TruckIcon',
        route: 'shipment-analytics',
        resource: 'ShipmentsAnalytics',
        action: 'manage',
        variant: 'lightblue',
      },
      {
        title: 'Delivery Status',
        icon: 'MapPinIcon',
        route: 'delivery-status-analytics',
        resource: 'DeliveryStatusAnalytics',
        action: 'manage',
        variant: 'lightblue',
      },
      {
        title: 'Users',
        icon: 'UsersIcon',
        route: 'users-analytics',
        resource: 'UsersAnalytics',
        action: 'manage',
        variant: 'lightblue',
      },
      // {
      //   title: 'Clicks',
      //   icon: 'MousePointerIcon',
      //   route: 'clicks-analytics',
      //   resource: 'ClicksAnalytics',
      //   action: 'manage',
      //   variant: 'lightblue',
      // },
      {
        title: 'Location',
        icon: 'MapIcon',
        route: 'location-analytics',
        resource: 'LocationAnalytics',
        action: 'manage',
        variant: 'lightblue',
      },
    ],
  },
  {
    title: 'VOUCHERS',
    icon: 'TagIcon',
    children: [
      {
        title: 'Vouchers Generator',
        route: 'vouchers-generator',
        icon: 'PlusIcon',
        resource: 'VouchersGenerator',
        action: 'manage',
        variant: 'red',
      },
      {
        title: 'Vouchers Logs',
        route: 'voucher-logs',
        icon: 'TagIcon',
        resource: 'VoucherLogs',
        action: 'manage',
        variant: 'darkblue',
      },
    ],
  },
  {
    title: 'LOGS',
    icon: 'BookIcon',
    children: [
      {
        title: 'Payment Status Logs',
        route: 'payment-status-logs',
        icon: 'CoffeeIcon',
        resource: 'PaymentStatusLogs',
        action: 'manage',
        variant: 'secondary',
      },
    ],
  },
]
