<template>
  <div>
    <b-row class="justify-content-between">
      <b-col
        cols="12"
        lg="2"
        class="mb-2"
      >
        <b-button
          variant="primary"
          class="w-100"
          @click.prevent="handleAddNewRole"
        >
          <feather-icon
            icon="UserPlusIcon"
            class="mr-50"
          />
          Add New Role
        </b-button>
      </b-col>

      <b-col
        cols="12"
        lg="2"
      >
        <b-dropdown
          id="mode"
          :text="mode.name"
          :variant="mode.color"
          class="w-100"
          split
          right
        >
          <b-dropdown-item
            v-for="(item, index) in modes"
            :key="index"
            :variant="item.color"
            @click.prevent="updateDisplayMode(item)"
          >
            <feather-icon :icon="item.icon" />
            <span class="pl-1">{{ item.name }}</span>
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <AppCollapse
          v-for="(item, index) in abilities"
          :key="index"
          type="margin"
        >
          <AppCollapseItem
            title=""
          >
            <template #header>
              <h5 class="text-secondary mb-0">
                {{ item.title }}
              </h5>
              <div v-if="mode.value === 1">
                <b-button
                  variant="flat-warning"
                  class="btn-icon"
                  @click.prevent="handleEditRole(item)"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="20"
                  />
                </b-button>

                <b-button
                  variant="flat-primary"
                  class="btn-icon"
                  @click.prevent="handleDeleteRole(item.id)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    size="20"
                  />
                </b-button>
              </div>
            </template>
            <b-row v-if="item.permission">
              <b-col
                v-for="(permission, pIndex) in item.permission.filter(permission => permission.subject !== 'Auth')"
                :key="pIndex"
                md="2"
                class="mb-1"
              >
                <b-badge
                  :variant="permission.variant ? permission.variant : 'primary'"
                  disabled
                  class="w-100 font-weight-bolder badge-padding"
                >
                  {{ permission.title.toUpperCase() }}
                </b-badge>
              </b-col>
            </b-row>
            <span
              v-else
              class="text-primary"
            >No permission is set for this role.</span>
          </AppCollapseItem>
        </AppCollapse>
      </b-col>
    </b-row>

    <AddEditAdminUserRoleModal
      :is-show="showAddEditAdminUserRoleModal"
      :pages="getPages"
      :selected-role="selectedRole"
      :abilities="abilities"
      :is-edit="isEdit"
      @reset-modal="handleResetModal"
      @success="handleSuccess"
    />
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { AddEditAdminUserRoleModal } from '@/components'
import { getUserData } from '@/auth/utils'
import axios from '@axios'
import VerticalNav from '@/navigation/vertical'

export default {
  name: 'RoleAccess',
  components: {
    AppCollapse,
    AppCollapseItem,
    AddEditAdminUserRoleModal,
  },
  data() {
    return {
      showAddEditAdminUserRoleModal: false,
      isLoading: false,
      isEdit: false,
      abilities: [],
      selectedRole: {
        title: null,
        permission: [],
      },
      modes: [
        {
          value: 0,
          name: 'Read-Only Mode',
          icon: 'EyeIcon',
          color: 'primary',
        },
        {
          value: 1,
          name: 'Editing Mode',
          icon: 'EditIcon',
          color: 'success',
        },
      ],
      mode: {
        name: 'Display Mode',
        value: 0,
        color: 'primary',
      },
    }
  },
  computed: {
    getPages() {
      return VerticalNav.map(page => {
        if (page.children) {
          return page.children.map(children => ({
            action: children.action,
            title: this.$helpers.formatTextToCapitalized(children.route, '-'),
            subject: children.resource,
            route: children.route,
            variant: children.variant,
          }))
        }
        return {
          action: page.action,
          title: this.$helpers.formatTextToCapitalized(page.route, '-'),
          subject: page.resource,
          route: page.route,
          variant: page.variant,
        }
      })
        .flat()
    },
  },
  created() {
    this.fetchAbilities()
  },
  methods: {
    async fetchAbilities() {
      const response = await axios.get('/admin-abilities')
      if (response.status === 200) {
        this.abilities = response.data
      }
    },
    handleSuccess() {
      this.fetchAbilities()
    },
    handleResetModal() {
      this.showAddEditAdminUserRoleModal = false
      this.isEdit = false
      this.selectedRole = {
        title: null,
        permission: [],
      }
    },
    handleAddNewRole() {
      this.showAddEditAdminUserRoleModal = true
    },
    handleEditRole(data) {
      this.selectedRole = data
      this.showAddEditAdminUserRoleModal = true
      this.isEdit = true
    },
    handleDeleteRole(roleId) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          const response = await axios.delete(`/admin-abilities/${roleId}`)
          if (response.status === 200) {
            this.$swal({
              icon: 'success',
              title: 'Deleted',
              text: 'Successfully Deleted The Role',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.fetchAbilities()
          }
        }
      })
    },
    updateDisplayMode(item) {
      const user = getUserData()
      if (!user.ability.title.includes('High Level Management', 'IT Managers', 'IT Engineers')) {
        this.resetEditingMode()

        this.$swal({
          icon: 'error',
          title: 'Forbidden!',
          text: 'Editing Mode only allowed by IT Department only.',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })

        return
      }
      this.mode = {
        name: item.name,
        value: item.value,
        color: item.color,
      }
    },
    resetEditingMode() {
      this.mode = {
        name: 'Read-Only Mode',
        value: 0,
        color: 'primary',
      }
    },
  },
}
</script>
